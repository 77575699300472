
footer {
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto; 
    background: #264D4C;
    display: flex;
    justify-content: center;
  }

  footer .inner {
    display: flex; 
    justify-content: center;
    width: 100%;
    padding-left: 100px;
    padding-right: 100px;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 0;
  }

  .footer-content {
    display: grid;
    grid-template-columns: 1fr auto;
    /* align-items: flex-start; */
    width: 100%;
    border-top: 1px solid #ccc;
    padding: 10px 20px;
  }

  .social-media-icons {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
  }
  
  .social-media-icons a {
    margin-left: 0px;
    margin-bottom: 5px;
    font-size: 20px;
    color: #fff;
    margin-right: 15px;
  }

  .social-media-icons a:last-child { 
    margin-right: 0; /* Remove margin from the last icon */
  }
  .contact-us {
    color: #fff; 
    font-family: "Libre Baskerville", serif;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  
  .text-right {
    color: #fff;
    font-family: "Libre Baskerville", serif;
    font-size: 12px; 
    line-height: 1;
    text-align: right;
    padding-top: 30px;
  }

  @media only screen and (max-width: 768px) {
    footer {
      position: relative; /* Change to relative */
      height: auto; /* Adjust height */
    }
    footer .inner {
      /* flex-direction: column;  */
      padding-left: 15px; /* Adjust padding */
      padding-right: 15px; /* Adjust padding */
      padding-top: 15px; 
    }

    .footer-content {
      grid-template-columns: 1fr; /* Simplify grid for mobile */
    }
    .social-media-icons {
      margin-left: 0; /* Reset margin */
      padding-bottom: 20px; /* Add padding */
      /* flex-direction: column; */
    }
    .social-media-icons a {
      font-size: 16px; /* Reduce font size for mobile */
      margin-right: 5px;
    }
  
    .contact-us {
      font-size: 14px; /* Reduce font size for mobile */
    }
  
    .text-right {
      padding-top: 15px; /* Reduce padding-top for mobile */
      font-size: 10px; /* Reduce font size for mobile */
    }
  }
  
  