
.text-to-right {
  direction: rtl;
  text-align: right;
}

.main-container {
  margin: 0;
}


@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lavishly+Yours&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

