/* body {
    margin: 0; 
  } */
.home-container {
    background-image: url('../../public/images/bludeagave.jpg');
    width: 100vw;
    height: 60vh; 
    background-size: cover; 
    background-position: top center; 
    background-repeat: no-repeat;
    display: flex; 
    flex-direction: column;
    justify-content: flex-end; 
    align-items: flex-start;
    padding-left: 10vw; 
  }

.text-container {
    text-align: left;
    font-size: 40px;
    color: #FFFFFF;
    font-family: "Libre Baskerville", serif;
    line-height: .7;  
    padding-left: 10vw; 
    padding-bottom: 100px;  
}


.bold {
    font-weight: bolder;
}

.underline {
    font-weight: bolder;
    border-bottom: 3px solid #fff; /* adjust border as needed */
    padding-bottom: 3px;
}
.custom-color {
    color: #AF6900; /* replace with your desired color */
    text-align: left;
    font-size: 12px;
}

.bottom-text {
    color: #264D4C; 
    font-family: "Libre Baskerville", serif; 
    margin-bottom: 30px; /* Adjust spacing as needed */
    padding-left: 6vw; 
  }
  
  .bottom-text p {
    font-size: 12px; /* Adjust font size as needed */
    line-height: 1.6;  /* Adjust line height as needed */
  }

  .underline-color {
    font-size: 48px; 
    font-weight: 500;
    border-bottom: 4px solid #AF6900; /* adjust border as needed */
    padding-bottom: 3px;
    box-sizing: border-box;
  }

  .we-are {
    font-size: 35px !important;
    line-height: 0 !important; 
    padding-top: 40px; 
  }

  .alumbrados {
    font-size: 48px; /* match font size with Los */
    font-weight: 500; /* make Alumbrados bold */
    line-height: .5 !important;
    white-space: nowrap;
  }

  .custom-text {
    font-size: 12px; /* adjust to match "We love our craft" size */
    line-height: 1.6;
  }

  .vision-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px; /* adjust padding as needed */
    text-align: center;
  }
  
  .vision {
    color: #264D4C; 
    font-family: "Libre Baskerville", serif;
    font-weight: 500;
    font-size: 48px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .vision-text {
    color: #264D4C; 
    font-family: "Libre Baskerville", serif;
    font-size: 15px;
    margin-bottom: 40px;
    text-align: center;
    padding-left: 250px;
    padding-right: 250px;
  }

  .invitation {
    display: block; 
    margin: 0 auto; 
    width: 50%; /* adjust image width as needed */
    padding-bottom: 40px;
  }

  .mission-container {
    display: grid;
    flex-direction: column;
    align-items: center;
    grid-template-columns: 2fr 5fr; 
    align-items: center;
    gap: 0; 
  }

  .our-mission {
    grid-column: 1;
    white-space: nowrap;
    color: #264D4C; 
    font-family: "Libre Baskerville", serif;
    font-size: 48px;
    text-align: left;
    padding-left: 19vw;
    font-weight: 500;
  }

  .carousel-nav-container {
    grid-column: 2;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative; 
  }

  .carousel-nav-container::before {
    content: "";
    position: absolute;
    bottom: 0;
    margin-right: 15vw; 
    width: 70%;
    height: 0.3px; /* Thin base line */
    background-color: rgba(51, 51, 51, 0.3);
  }
  
  .carousel-nav {
    margin-left: 30px; 
    top: 0;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 10px;
    background-color: transparent;
  }
  
  .carousel-nav-item {
    display: inline-block;
    font-family: "Libre Baskerville", serif;
    font-size: 15px;
    color: #1A1A1A;
    opacity: 0.6;
    margin: 0 30px;
    cursor: pointer;
    font-weight: 100;
    position: relative; 
  }
  
  .carousel-nav-item.active {
    color: #1A1A1A;
    font-weight: bold;
    opacity: .7;
  }

  .carousel-nav-item.active::after {
    content: "";
    position: absolute;
    bottom: -10px; /* adjust to match border thickness */
    left: 0;
    width: 100%;
    background-color: rgba(51, 51, 51, 0.7); /* thicker border color */
    height: 2px;
    transform: scaleX(1); /* animation */
    z-index: 1; 
}

  .active-slide-title {
    color: #1A1A1A;
    font-weight: 80; /* or 700, 800, etc. */
  }

  .carousel-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
  }
  
  .carousel-text-wrapper {
    padding-left: 20px;
    padding-right: 12vw;
    margin-bottom: 60px;
    font-size: 22px;
    font-weight: 500;
    color: #264D4C; 
    font-family: "Libre Baskerville", serif;
    text-align: left;
    border-left: 4px solid #AF6900;
    margin-left: 40vw; 
    height: 75px;
  }
  
  .carousel-title {
    font-size: 24px;
    font-weight: bold;
    color: #1A1A1A;
    margin-bottom: 10px;
  }
    
  .carousel-image {
    margin-top: 50px; 
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  .connect {
    color: #264D4C; 
    font-family: "Libre Baskerville", serif;
    font-size: 25px;
    text-align: center;
    padding-left: 150px;
    padding-right: 150px;
    line-height: 15px;
  }

  .connect-container {
    text-align: center;
    padding-bottom: 50px; 
  }

  .connect-button {
    display: inline-block;
    margin: 20px auto; 
    text-align: center;
    font-family: "Libre Baskerville", serif;
    padding: 10px 20px;
    border-top: 1.5px solid #AF6900;
    border-bottom: 1.5px solid #AF6900;
    background-color: transparent;
    color: #264D4C;
    text-decoration: none;
  }

  @media only screen and (max-width: 768px) {
    .home-container {
      height: 50vh; 
      width: auto;
      justify-content: center; 
    }
    .col-one {
      padding-right: 0 !important;
    }
  
    .text-container {
      font-size: 25px; /* reduce font size for mobile */
      padding-left: 5px; /* reduce padding for mobile */
      padding-bottom: 10px; /* reduce padding for mobile */
      line-height: 1.38;
    }
    .custom-color {
      font-size: 18px; 
      line-height: 1;
      /* text-align: center;
      align-items: center; */
    }

    .custom-text {
      font-size: 15px !important;
    }

    .bottom-text {
        margin-bottom: 10px; 
        padding-left: 10px; 
    }
  
    .bottom-text p {
      line-height: 1.2; 
    }

    .col-two{
        padding-top: 20px !important; 
    }
    .vision-text {
        padding-left: 10px;
        padding-right: 10px;
      }
    
      .connect {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        font-size: 15px;
      }
    
      .connect-button {
        padding: 8px;
        font-size: 16px;
      }
    
      .invitation {
        width: 100%; 
      }
    
      .custom-color {
        font-size: 12px;
      }
    
      .underline-color {
        font-size: 35px;
      }
    
      .alumbrados {
        font-size: 35px;
      }
    
      .we-are {
        font-size: 25px !important; 
        padding-top: 30px;
      }

      .carousel-image {
        margin-top: 20px; 
      }

      .carousel-nav {
        margin-left: 0 !important; 
      }
      .carousel-nav-container {
        /* padding-right: 10vw; */
        /* max-width: 75vw; */
        width: 50%; 
      }

      .carousel-nav-item {
        display: none; 
        font-size: 10px; 
      }
      .carousel-nav-item.active {
        display: block;
      }

      .carousel-nav-item.active::after {
        bottom: -11px; 
      }

      .carousel-nav-container::before {
        position: absolute;
        bottom: 0;
        width: 35%;
        height: 0.3px; 
        background-color: rgba(51, 51, 51, 0.3);
      }
    
      .carousel-text-wrapper {
        margin-left: 10vw;
        font-size: 15px;
        margin-bottom: 10px; 
        width: 100%; 
        height: auto; 
      }
    
      .mission-container {
        grid-template-columns: 1fr;
        width: 100%;
      }
    
      .our-mission {
        padding-left: 10vw;
      }
  }
  