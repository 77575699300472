.container-fluid {
    display: flex; 
    top: 0;
    left: 0; 
    width: 100%;
    height: 6%;
    z-index: 1000;
    padding-left: 0 !important; 
    padding-right: 0 !important;
    margin: 0; 
    justify-content: space-between;
    background: #fff;
    align-items: center;
}

.brandLogo {
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    height: 135px;
    width: auto;
    margin-top: 55px;
}

.custom-nav {
    transition: 0.3s; /* add a smooth transition effect */
    font-size: 12px;
    font-family: "Libre Baskerville", serif;
    font-weight: 400;
    font-style: normal;
    margin-left: auto;
    margin-right: 5%;
  }

  .custom-nav .nav-link {
    color: #264D4C !important;
  }

.navbar .nav-link {
    position: relative; 
    color: #264D4C;
    display: inline-block;
    font-weight: 900;
    transition: .3s; 
}

.navbar .nav-link::before {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #264D4C;
    transition: 0.3s;
  }
  
.navbar .nav-link:hover::before {
    width: calc(100% - 10px);
    left: 6px;
  }

.navbar .nav-link:hover {
    font-size: 12px; 
    color: #264D4C;
    text-decoration-color: #264D4C; /* white underline */
}

.custom-nav ul {
    display: flex;
    list-style-type: none;
}

.custom-nav ul.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    background-color: #fff;
}

@media only screen and (max-width: 768px) {
    .container-fluid {
      height: 10%; /* Adjust height */
      flex-direction: column; /* Stack elements vertically */
      justify-content: center;
      align-items: center;
    }
    
    .brandLogo {
      position: relative; /* Change to relative */
      transform: none;
      /* top: 0;  */
      left: 0; 
      margin-top: 10px;
      height: 80px; /* Adjust logo size */
    }
    
    .custom-nav {
      margin-top: 0px;
      margin-left: 0;
      margin-right: 0;
    }
    
    .custom-nav ul {
        display: none; 
        text-align: center;
    }
    
    .custom-nav ul.show {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        position: absolute;
        top: 100%; 
        left: 0; 
        right: 0; 
        padding-top: 0px;
        padding-bottom: 10px; 
        margin-right: 9vw; 
        width: auto;
        z-index: 1000;
      }

    .custom-nav li {
      margin-bottom: 10px;
    }
    
    .custom-nav a {
        font-size: 18px; 
        padding: 10px 0; 
    }
    
    /* Hamburger menu icon */
    .menu-icon {
        display: block;
        cursor: pointer; 
        z-index: 1; 
        margin-top: 10px;
        margin-right: 3vw; 
    }

    .menu-icon span {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px auto;
        background-color: #264D4C;
    }
  }

