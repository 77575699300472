.contact-inquiry {
    color: #264D4C; 
    font-family: "Libre Baskerville", serif; 
    margin-bottom: 30px; /* Adjust spacing as needed */
    padding-left: 10vw; /* Align with top text */
    padding-top: 20vh; 
}

.text-start{
    color: #264D4C; 
    font-family: "Libre Baskerville", serif; 
    font-size: 48px; 
    font-weight: 500;
}

.underline-contact {
    color: #264D4C; 
    font-family: "Libre Baskerville", serif; 
    margin-bottom: 30px; /* Adjust spacing as needed */
    font-size: 48px; 
    font-weight: 500;
    border-bottom: 4px solid #AF6900; /* adjust border as needed */
    padding-bottom: 3px;
  }

  .form-row {
    display: flex; 
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px; 
    flex-wrap: nowrap;
    padding-left: 10vw;
    width: 80%; 
    gap: 80px;
  }

  .form-row .form-group {
    flex-basis: 48%;
  }
  
  .form-group {
    display: flex; 
    flex-direction: row;
    align-items: center; 
    margin-bottom: 20px; 
    position: relative; 
    padding-bottom: 5px; 
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }

  .form-group label {
    flex-basis: 40%;
    font-family: "Libre Baskerville", serif; /* Font family */
    font-size: 18px; /* Font size */
    font-weight: 500; /* Font weight */
    color: #264D4C; /* Text color */
    margin-right: 5px; 
    margin-bottom: 10px; 
  }

.form-group input[type="text"], 
.form-group input[type="email"], 
.form-group input[type="tel"], 
.form-group input[type="date"] {
    flex-grow: 1;
    border: none; 
    box-shadow: none;
    outline: none; 
    font-family: "Libre Baskerville", serif;
}

.form-group select {
    border: none; 
    box-shadow: none;
    font-family: "Libre Baskerville", serif;
}

.phone-input {
    width: 70%;
  }

.form-group textarea {
    width: 100%;
    padding: 10px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 30vh; /* Set minimum height */
    min-width: 55vw;
    resize: none;
  }
  
  .form-group input[type="checkbox"] {
    margin-right: 10px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group label {
    font-weight: normal; /* Remove bold font weight */
    display: inline-block; 
  }
  
  .form-group select[name="countryCode"] {
    width: 100px; /* Adjust select width */
    margin-right: 10px;
  }
  
  .form-group input[type="tel"][name="phone"] {
    flex-grow: 1; /* Take remaining space */
    width: 100%;
  }

  .form-group.checkbox-group {
    display: flex;
    align-items: flex-start; 
    width: 100%;
    border-bottom: none; 
  }

  .form-group.checkbox-group label {
    flex-grow: 1; /* Makes the label take up the remaining space */
    margin-left: 10px; /* Adds space between the checkbox and label */
    font-family: "Libre Baskerville", serif;
    font-size: 16px; /* Adjust font size if needed */
    color: #264D4C;
}

  .submit-btn {
    font-family: "Libre Baskerville", serif; 
    font-size: 18px; 
    background-color: #264D4C; /* Green */
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
    margin-bottom: 50px; /* Adjust spacing as needed */
    margin-left: 10vw;
  }

  .submit-btn:hover {
    border: 2px solid #fff; /* White border on hover */
    box-shadow: 0 0 0 2px #264D4C, 0 0 0 4px #fff; /* Optional box-shadow */
    transition: 0.35s ease-out;
  }

  .inquiry-group {
    display: flex;
    flex-direction: column; /* Stack label and textarea vertically */
    align-items: flex-start; /* Align items to the left */
    margin-bottom: 20px;
    border-bottom: none;
}

.inquiry-group label {
    font-family: "Libre Baskerville", serif;
    font-size: 18px;
    font-weight: 500;
    color: #264D4C;
    margin-bottom: 10px; /* Add space between the label and the textarea */
}

.inquiry-group textarea {
    width: 100%;
    padding: 10px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 30vh; /* Set a minimum height for the text area */
    min-width: 55vw;
}

/* General styling for the react-select component */
.contact .react-select__control {
    background-color: #fff;
    border: none; 
    border-radius: 4px;
    padding: 5px;
    box-shadow: none;
    font-family: "Libre Baskerville", serif;
    width: 100%; /* Match width of other form inputs */
    margin-right: 10px;
}

.contact .react-select__control--is-focused {
    border: none; 
    box-shadow: none;
}

.contact .react-select__single-value {
    color: #495057;
    font-family: "Libre Baskerville", serif;
}

.contact .react-select__placeholder {
    color: #6c757d;
    font-family: "Libre Baskerville", serif;
}

.contact .react-select__menu {
    background-color: #fff;
    border: 1px solid #ced4da;
    z-index: 999;
}

.contact .react-select__option {
    padding: 10px;
    color: #495057;
    font-family: "Libre Baskerville", serif;
}

.contact .react-select__option--is-focused {
    background-color: #e9ecef;
}

.contact .react-select__dropdown-indicator {
    color: #495057;
}

.contact .react-select__indicator-separator {
    display: none;
}

/* General phone input container styling */
.phone-input .react-tel-input {
    width: 100%; /* Ensure it takes up full width */
  }
  
  /* Styling the phone number input field */
  .phone-input .form-control {
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    padding-left: 50px; 
    border: none;
    box-shadow: none;
    outline: none;
    background-color: #fff;
  }
  
  /* Dropdown button (the flag dropdown) */
  .phone-input .flag-dropdown {
    background-color: transparent !important; /* Make the background transparent */
    border: none !important;
    outline: none;
    cursor: pointer;
  }
  
  /* Styling the country flag within the dropdown */
  .phone-input .flag {
    width: 24px;
    height: 16px;
    margin-right: 10px; /* Adds some space between the flag and the input */
  }
  
  /* When the dropdown is focused */
  .phone-input .react-tel-input:focus .form-control {
    border-color: none;
    box-shadow: none;
  }
  
  /* Dropdown menu styling */
  .phone-input .country-list {
    background-color: #fff;
    border: none;
    z-index: 1000;
  }
  
  /* Option in the dropdown menu */
  .phone-input .country {
    padding: 10px;
    font-family: "Libre Baskerville", serif;
    color: #495057;
  }
  
  /* Highlighted country option on hover */
  .phone-input .country:hover {
    background-color: #e9ecef;
  }
  
  @media (max-width: 768px) {
    .contact-inquiry {
      padding-top: 5vh !important; 
    } 

    .text-start, .underline-contact {
        font-size: 24px; /* Smaller font size for mobile */
    }

    .form-group label {
      padding-left: 5vw;
      font-size: 15px;
      flex-direction: column;
      flex-basis: 100%; /* Labels take full width */
      margin-bottom: 5px; /* Adjust spacing */
      margin-right: 1vw; 
    }

    .form-group.checkbox-group label {
      font-size: 15px;
    }

    .phone-input .form-control {
      width: 50vw; 
    } 

    .form-row {
      flex-direction: column; /* Stack the form elements vertically */
      flex-wrap: wrap; /* Allow items to wrap */
      width: 100%; /* Ensure the form doesn't scroll horizontally */
      padding-left: 5vw; /* Adjust padding for mobile */
      gap: 0 !important; 
  }

    .form-group {
      width: 100%; 
      margin-bottom: 4vh;
  }

  .form-group input[type="date"] {
    width: calc(100% - 10px);
    padding: 5px; 
  }

  .form-group input[type="tel"][name="phone"] {
    margin-left: 0; 
  }

  .form-group textarea {
    width: 100%; /* Ensure textarea takes full width */
}

  .submit-btn {
    margin-left: 5vw; /* Center the submit button */
  }
}

